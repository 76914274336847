import { render, staticRenderFns } from "./Campaigns.vue?vue&type=template&id=46d571c2&scoped=true"
import script from "./Campaigns.vue?vue&type=script&lang=js"
export * from "./Campaigns.vue?vue&type=script&lang=js"
import style0 from "./Campaigns.vue?vue&type=style&index=0&id=46d571c2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d571c2",
  null
  
)

export default component.exports
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QTooltip,QForm,QInput,QSelect,QBtn,QDialog});qInstall(component, 'directives', {ClosePopup});
